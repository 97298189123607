import { onMounted, reactive, nextTick, toRefs, toRef, ref } from "@vue/composition-api";

export default function useFlowchartValidations(nodes, invalidNodes = null, missingNodeData = null) {

  const firstNode = nodes.at(0);
  const lastNode = nodes.at(-1);

  const addToInvalidNodes = (node, reason) => {
    if (!invalidNodes) throw new Error('addToInvalidNodes: invalidNodes must be not null');

    invalidNodes.value.push({ nodeId: node.id, nodeName: node.name, reason });
  };

  const checkIfInputsAreEmpty = (node) => {
    let atLeastOneEmpty = false;

    for (const input in node.inputs) {
      const i = node.inputs[input];
      atLeastOneEmpty = !i.connections.length;
    }

    return atLeastOneEmpty;
  }

  const checkIfOutputsAreEmpty = (node) => {
    let atLeastOneEmpty = false;

    for (const output in node.outputs) {
      const o = node.outputs[output];
      atLeastOneEmpty = !o.connections.length;
    }

    return atLeastOneEmpty;
  }

  const checkEmptyConnections = (node) => {

    if (node.class === 'Terminate') return;

    if (node.id === firstNode.id) {
      if (checkIfOutputsAreEmpty(node)) {
        addToInvalidNodes(node, 'connections_empty');
      }
    } else {
      if (checkIfInputsAreEmpty(node) || checkIfOutputsAreEmpty(node)) {
        addToInvalidNodes(node, 'connections_empty');
      }
    }

  };

  const checkMaxConnections = (node, maxNodeConnections) => {

    const inputsQtyExceeded = Object.keys(node.inputs).length ?
      node.inputs.input_1.connections.length > maxNodeConnections :
      false;

    const outputsQtyExceeded = Object.keys(node.outputs).length ?
      node.outputs.output_1.connections.length > maxNodeConnections :
      false;

    if (inputsQtyExceeded || outputsQtyExceeded) {
      addToInvalidNodes(node, 'connections_qty_exceeded');
    }
  };

  const isEmpty = (item) => {
    if (Array.isArray(item) && !item.length) return true;
    if (JSON.stringify(item) === '{}') return true;
    if (!item) return true;

    return false;
  }

  const checkNodeValidity = (node) => {

    if (!missingNodeData) throw new Error('checkNodeValidity: missingNodeData must be not null');

    if (node.class === 'Node' && node.data.cmd !== 'custom') {

      const requirements = node.data.requirements;
      const storage = node.data.storage;
      let isValid = true;

      console.log('checkNode', node)

      requirements.forEach(req => {
        if (!missingNodeData.value.find(n => n.id === node.id)) {
          let hasError = false;

          console.log('validation', storage.hasOwnProperty(req.type))

          if (!storage.hasOwnProperty(req.type)) {

            if (node.data.cmd === 'send:notification' && 
                storage.type !== 'webhook' && 
                !['authorization', 'verb'].includes(req.type)) 
              hasError = true;

          } else if (isEmpty(storage[req.type])) {
            if(node.name != 'assignment_cameras'){
              hasError = true;
            }
          }

          if (hasError) {
            missingNodeData.value.push({
              id: node.id,
              missingData: req.type,
              node,
            });
            isValid = false;
          }
        }
      });
    }
  };

  return {
    addToInvalidNodes,
    checkIfInputsAreEmpty,
    checkIfOutputsAreEmpty,
    checkEmptyConnections,
    checkMaxConnections,
    isEmpty,
    checkNodeValidity,
  };
}
