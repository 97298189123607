<template>
  <div>
    <label class="d-block">
      {{ $t(config.label) }}
    </label>
    <validation-provider
      :name="$t(config.name)"
      :rules="config.rules"
      v-slot="{ errors }"
    >
      <v-select
        v-model="model"
        :options="items"
        :clearable="false"
        :reduce="(val) => val.value"
        input-id="notification_command"
        :multiple="multiple"
        @input="$emit('input', model)"
      />
      <p v-if="config.hint" class="mb-0 small">{{ $t(config.hint) }}</p>
      <p v-if="errors" class="small text-danger">{{ errors[0] }}</p>
    </validation-provider>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    vSelect,
    ValidationProvider,
  },
  props: {
    value: Array | Object,
    multiple: Boolean | Number,
    config: Object,
    context: Object,
  },
  data() {
    return {
      model: null,
      items: [
        {
          value: "whatsapp",
          label: "Whatsapp",
        },
        {
          value: "sms",
          label: "SMS",
        },
        {
          value: "email",
          label: "Email",
        },
        {
          value: "webhook",
          label: "Webhook",
        },
      ],
    };
  },
  created() {
    this.model = this.value;
  },
};
</script>

<style></style>
