<template>
  <div>
    <label class="d-block">
      {{ $t(config.hint) }}
    </label>
    <validation-provider
      :name="$t(config.name)"
      :rules="config.rules"
      v-slot="{ errors }"
    >
      <v-select
        v-if="!loading"
        v-model="model"
        :options="itemsOptions"
        :clearable="false"
        :reduce="(val) => val.value"
        input-id="density_device"
        :multiple="multiple"
        @input="$emit('input', model)"
      />
      <p v-else>Loading devices...</p>
      <p v-if="config.hint" class="mb-0 small">{{ $t(config.hint) }}</p>
      <p v-if="errors" class="small text-danger">{{ errors[0] }}</p>
    </validation-provider>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { ValidationProvider } from "vee-validate";
import axios from "@axios";

export default {
  components: {
    vSelect,
    ValidationProvider,
  },
  props: {
    value: Array | Object,
    multiple: Boolean | Number,
    config: Object,
    context: Object,
  },
  data() {
    return {
      model: null,
      items: [],
      options: [],
      loading: true,
    };
  },
  created() {
    this.model = this.value;
  },
  async mounted() {
    if (!this.items.length) {
      const { data } = await axios.get("v1/density_device");
      this.items = data.data.map((item) => ({
        value: item.devices.id,
        label: item.description,
      }));
      this.loading = false;
    }
  },
  computed: {
    itemsOptions() {
      if (this.options.length) {
        return this.options;
      }

      this.options = [...this.items];

      return this.options;
    },
  },
};
</script>

<style></style>
