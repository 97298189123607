<template>
  <div ref="el">
    <div v-if="nodeData">
      <span class="d-block">
        {{ resolveNodeName(nodeData) }}
      </span>
      <span
        v-if="nodeData.data.cmd !== 'custom'"
        class="config-icon"
        @click="openConfigModal"
      >
        <feather-icon icon="SettingsIcon" size="16" class="" />
      </span>
      <div class="action-plan-plan" v-if="source === 'action_plan'">
        <b-form-checkbox
          class="check-box-node"
          v-model="data.required"
          value="1"
          unchecked-value="0"
          switch
          size="sm"
          @change="onChange"
          :key="`${data.key}`"
        >
          Requerido
        </b-form-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import nodeMixin from "@/mixins/nodeMixin";
import { EventBus } from "@/libs/event-bus";

export default {
  props: {
    source: {
      type: String,
      default: "commands",
    },
  },
  mixins: [nodeMixin],
  data() {
    return {
      data: {
        key: 1,
      },
    };
  },
  async mounted() {
    await this.$nextTick();
    this.getNodeId();
    this.getNodeData();

    if (this.source === "action_plan") {
      this.$nextTick(() => {
        this.data.required = this.nodeData.data.required;
        this.data.key++;
      });
    }
  },
  methods: {
    onChange(val) {
      setTimeout(() => {
        this.nodeData.data.required = val;
        this.updateNodeData();
      }, 500);
    },
    openConfigModal() {
      EventBus.emit("node:open-modal", this.nodeId);
    },
    resolveNodeName(nodeData) {
      return nodeData.data.cmd !== "custom"
        ? this.$t(`commands.list.${nodeData.name}.name`)
        : nodeData.name;
    },
  },
};
</script>

<style></style>
