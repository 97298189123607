<template>
  <div>
    <label class="d-block">Phone</label>
    <div class="d-flex">
      <v-select
        v-model="countryCode"
        :options="countries"
        :clearable="false"
        :create-option="(text) => ({ label: text, value: text })"
        class="mr-1"
        @input="onCountryCodeInput"
        style="min-width: 150px"
      />
      <input
        type="number"
        v-model="model"
        class="form-control"
        @input="onInput"
        style="max-width: 300px"
      />
    </div>
  </div>
</template>

<script>
import axios from "@axios";
import vSelect from "vue-select";

export default {
  components: {
    vSelect,
  },
  props: {
    value: Array | Object,
    multiple: Boolean | Number,
    config: Object,
    type: String,
  },
  data() {
    return {
      model: null,
      countryCode: null,
      countries: [],
    };
  },
  created() {
    this.model = this.value;
  },
  async mounted() {
    const { data } = await axios.get("v1/country");
    this.countries = data.data
      .filter((c) => c.telephone_code !== 0)
      .map((c) => ({
        label: `${c.code} (+${c.telephone_code})`,
        value: c.telephone_code,
      }));
    if (this.model) {
      this.countryCode = this.countries.find(
        (c) => c.value == this.model.substring(0, 2)
      );
      this.model = this.model.substring(2);
    }
  },
  computed: {},
  methods: {
    onInput(evt) {
      this.$emit("input", `${this.countryCode.value}${evt.target.value}`);
    },
    onCountryCodeInput(evt) {
      this.$emit("input", `${evt.value}${this.model}`);
    },
  },
};
</script>

<style></style>
