<template>
  <div>
    <p class="text-center lead">{{ title }}</p>
    <ul class="node-list list-unstyled px-1">
      <li
        v-for="(node, index) in filteredNodeList"
        :key="index"
        draggable="true"
        :data-node="node.item"
        class="drag-drawflow mb-1"
        @dragstart="drag($event)"
      >
        <div
          :class="[
            'btn btn-block node',
            { custom: node.data && node.data.cmd === 'custom' },
          ]"
        >
          {{ node.label }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
const sortFn = (a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0);

export default {
  props: {
    search: {
      type: String,
    },
    list: {
      type: Array,
    },
    title: {
      type: String,
    },
  },
  computed: {
    filteredNodeList() {
      if (!this.search) return this.list.sort(sortFn);
      return this.list
        .filter((item) => item.label.toLowerCase().includes(this.search.toLowerCase()))
        .sort(sortFn);
    },
  },
  methods: {
    drag(ev) {
      if (ev.type === "touchstart") {
        const data = ev.target.closest(".drag-drawflow").getAttribute("data-node");
        this.$emit("node-drag-start", data);
      } else {
        ev.dataTransfer.setData("node", ev.target.getAttribute("data-node"));
      }
    },
  },
};
</script>

<style></style>
