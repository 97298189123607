<template>
  <div>
    <label class="d-block">
      {{ $t(config.label) }}
    </label>
    <validation-provider
      :name="$t(config.name)"
      :rules="showFromAlarm ? 'required' : config.rules"
      v-slot="{ errors }"
    >
      <v-select
        :options="userViewsOptions"
        v-model="model"
        :clearable="false"
        :reduce="(val) => val.value"
        input-id="id_unit"
        :multiple="multiple"
        :disabled="fromAlarm"
        @input="$emit('input', model)"
      />
      <p v-if="errors" class="small text-danger">{{ errors[0] }}</p>
    </validation-provider>
    <div class="form-group form-check mt-1" v-if="showFromAlarm">
      <validation-provider vid="user_views_from_alarm">
        <input
          type="checkbox"
          class="form-check-input"
          name="user_views_from_alarm"
          v-model="fromAlarm"
          value="from_alarm"
        />
      </validation-provider>
      <label class="form-check-label">
        {{ $t("commands.form.location.fields.from_alarm") }}
      </label>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { ValidationProvider } from "vee-validate";
import store from "@/store";

export default {
  components: {
    vSelect,
    ValidationProvider,
  },
  props: {
    value: Array | Object,
    multiple: Boolean | Number,
    config: Object,
    context: Object,
  },
  data() {
    return {
      model: null,
      options: [],
      fromAlarm: false,
      showFromAlarm: false,
    };
  },
  created() {
    this.showFromAlarm = this.context.flowchartConfig.alarm_compatible;
    this.model = this.value || null;
    this.fromAlarm = this.showFromAlarm && this.value === "from_alarm";
  },
  mounted() {
    if (!this.userViews.length) {
      store.dispatch("grid/getUserViews");
    }
  },
  watch: {
    fromAlarm: {
      handler(value) {
        if (value) {
          this.$emit("input", "from_alarm");
          this.model = null;
        } else {
          this.$emit("input", null);
        }
      },
      immediate: true,
    },
  },
  computed: {
    userViews() {
      return store.getters["grid/getUserViews"].map((userv) => ({
        label: userv.name,
        value: userv.id,
      }));
    },
    userViewsOptions() {
      if (this.options.length) {
        return this.options;
      }

      this.options = [...this.userViews];

      return this.options;
    },
  },
  methods: {},
};
</script>

<style></style>
