<template>
  <div>
    <label class="d-block">
      {{ $t(config.label) }}
    </label>
    <validation-provider
      :name="$t(config.name)"
      :rules="config.rules"
      v-slot="{ errors }"
    >
      <input
        v-model="model"
        class="form-control"
        input-id="filter"
        @input="$emit('input', model)"
      />
      <p v-if="config.hint" class="mb-0 small">{{ $t(config.hint) }}</p>
      <p v-if="errors" class="small text-danger">{{ errors[0] }}</p>
    </validation-provider>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationProvider,
  },
  props: {
    value: Array | Object,
    multiple: Boolean | Number,
    config: Object,
    context: Object,
  },
  data() {
    return {
      model: null,
    };
  },
  created() {
    this.model = this.value;
  },
};
</script>

<style></style>
