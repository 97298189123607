<template>
  <div>
    <label class="d-block">{{ $t(label) }}</label>
    <validation-provider :rules="rules" :name="$t(name)" v-slot="{ errors }">
      <v-select
        v-if="fieldType === 'select'"
        v-model="model"
        :clearable="false"
        taggable
        :multiple="multiple"
        :create-option="(text) => ({ label: text, value: text })"
        @input="$emit('input', model)"
      />
      <input
        v-if="fieldType === 'boolean'"
        type="checkbox"
        v-model="model"
        @change="$emit('input', model)"
      />
      <input
        v-else
        v-model="model"
        :type="fieldType"
        class="form-control"
        @input="inputHandler(type)"
        :disabled="type === 'radius' && context.model.location !== 'from_alarm'"
        v-properties="properties"
      />
      <p v-if="hint" class="mb-0 small">{{ $t(hint) }}</p>
      <p v-if="errors" class="small text-danger">{{ errors[0] }}</p>
    </validation-provider>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { EventBus } from "@/libs/event-bus";
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    vSelect,
    ValidationProvider,
  },
  props: {
    value: Array | Object,
    multiple: Boolean | Number,
    config: Object,
    type: String,
    context: Object,
  },
  data() {
    return {
      model: null,
    };
  },
  created() {
    this.model = this.value;
    if (this.type === "radius") {
      EventBus.on("location_field:data", this.onLocationFieldData);
    }
  },
  beforeDestroy() {
    EventBus.off("location_field:data");
  },
  computed: {
    label() {
      return this.config.label;
    },
    name() {
      return this.config.name;
    },
    fieldType() {
      return this.config.type;
    },
    hint() {
      return this.config.hint || null;
    },
    options() {
      return this.config.options || {};
    },
    properties() {
      return this.config.properties || [];
    },
    rules() {
      return this.config.rules || "";
    },
    alarmCompatible() {
      return this.context.flowchartConfig.alarm_compatible;
    },
  },
  methods: {
    onLocationFieldData(data) {
      if (this.type === "radius") {
        this.model = data ? data.radius : null;
        this.$emit("input", this.model);
      }
    },
    inputHandler(field) {
      if (field === "radius" || field === "delay") {
        this.model = this.model > 0 ? this.model : null;
        if (this.model === null) {
          const min = this.properties.find((p) => p.name === "min");
          this.$emit("input", min ? min.value : 5);
        } else {
          this.$emit("input", this.model);
        }
      } else {
        this.$emit("input", this.model);
      }
    },
  },
};
</script>

<style></style>
