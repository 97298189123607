<template>
  <div>
    <label class="d-block">
      {{ $t(config.label) }}
    </label>
    <validation-provider
      :name="$t(config.name)"
      :rules="config.rules"
      v-slot="{ errors }"
    >
      <v-select
        :options="centers"
        v-model="model"
        :clearable="false"
        :reduce="(val) => val.value"
        input-id="id_center"
        :multiple="multiple"
        @input="$emit('input', model)"
      />
      <p v-if="config.hint" class="mb-0 small">{{ $t(config.hint) }}</p>
      <p v-if="errors" class="small text-danger">{{ errors[0] }}</p>
    </validation-provider>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { ValidationProvider } from "vee-validate";
import store from "@/store";

export default {
  components: {
    vSelect,
    ValidationProvider,
  },
  props: {
    value: Array | Object,
    multiple: Boolean | Number,
    config: Object,
    context: Object,
  },
  data() {
    return {
      model: null,
    };
  },
  created() {
    this.model = this.value;
  },
  mounted() {
    if (!this.centers.length) {
      store.dispatch("center/getAll");
    }
  },
  computed: {
    centers() {
      return store.getters['center/getSelectedItems'];
    },
  },
};
</script>

<style></style>
