<template>
  <div>
    <label class="d-block">Layouts</label>
    <validation-provider
      :name="$t(config.name)"
      :rules="config.rules"
      v-slot="{ errors }"
    >
      <v-select
        :options="layouts"
        v-model="model"
        :clearable="false"
        :reduce="(val) => val.value"
        :multiple="multiple"
        @input="$emit('input', model)"
        v-if="layouts.length"
      >
        <template #option="{ layout }">
          <div :style="styles">
            <layout-preview :key="layout.id" :layout="layout" />
          </div>
        </template>
        <template #selected-option="{ layout }">
          <div :style="styles">
            <layout-preview :key="layout.id" :layout="layout" />
          </div>
        </template>
      </v-select>
      <p v-else class="lead">Loading...</p>
      <p v-if="config.hint" class="mb-0 small">{{ $t(config.hint) }}</p>
      <p v-if="errors" class="small text-danger">{{ errors[0] }}</p>
    </validation-provider>
  </div>
</template>

<script>
import vSelect from "vue-select";
import store from "@/store";
import { ValidationProvider } from "vee-validate";
import LayoutPreview from "@/views/components/GridView/widgets/LayoutPreview";

export default {
  components: {
    vSelect,
    ValidationProvider,
    LayoutPreview,
  },
  props: {
    value: Array | Object,
    multiple: Boolean | Number,
    config: Object,
    context: Object,
  },
  data() {
    return {
      model: null,
      styles: {
        width: "200px",
        marginLeft: "110px",
      },
    };
  },
  created() {
    this.model = this.value;
  },
  mounted() {
    if (!this.layouts.length) {
      store.dispatch("layouts/getUserLayouts");
    }
  },
  computed: {
    layouts() {
      return store.getters["layouts/getUserLayouts"].map((layout) => {
        return {
          value: layout.id,
          label: layout.name,
          layout,
        };
      });
    },
  },
};
</script>
