<template>
  <div>
    <label class="d-block">{{ $t(config.label) }}</label>
    <validation-provider
      :rules="showFromAlarm ? 'required_if:cameras_from_alarm,false' : config.rules"
      v-slot="{ errors }"
      :name="$t(config.name)"
    >
      <v-select
        v-model="model"
        :options="formattedOptions"
        :filterable="false"
        @search="onSearch"
        :multiple="multiple"
        :clereable="multiple"
        ref="selectable"
        :disabled="fromAlarm"
        @input="$emit('input', model)"
      >
        <template #list-footer>
          <li class="pagination">
            <b-button
              variant="outline-primary"
              size="sm"
              :disabled="!hasPrevPage"
              @click="prevPage"
            >
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </b-button>
            <b-button
              variant="outline-primary"
              size="sm"
              :disabled="!hasNextPage"
              @click="nextPage"
            >
              <feather-icon icon="ChevronRightIcon" size="18" />
            </b-button>
          </li>
        </template>
      </v-select>
      <p v-if="config.hint" class="mb-0 small">{{ $t(config.hint) }}</p>
      <p v-if="errors" class="small text-danger">{{ errors[0] }}</p>
    </validation-provider>
    <div class="form-group form-check mt-1" v-if="showFromAlarm">
      <validation-provider vid="cameras_from_alarm">
        <input
          type="checkbox"
          class="form-check-input"
          name="cameras_from_alarm"
          v-model="fromAlarm"
          value="from_alarm"
        />
      </validation-provider>
      <label class="form-check-label">
        {{ $t("commands.form.location.fields.from_alarm") }}
      </label>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { debounce } from "lodash";
import { BButton } from "bootstrap-vue";
import store from "@/store";
import { EventBus } from "@/libs/event-bus";
import { ValidationProvider } from "vee-validate";
import axios from "@axios";

export default {
  components: {
    vSelect,
    BButton,
    ValidationProvider,
  },
  props: {
    value: Array | Object,
    multiple: { type: Boolean, required: false, default: false },
    config: Object,
    context: Object,
  },
  data() {
    return {
      model: null,
      fromAlarm: false,
      search: "",
      offset: 0,
      limit: 8,
      currentPage: 1,
      meta: null,
      options: [],
      showFromAlarm: false,
    };
  },
  created() {
    this.showFromAlarm = this.context.flowchartConfig.alarm_compatible;
    this.model = this.value || null;
    this.fromAlarm = this.showFromAlarm && this.value === "from_alarm";
  },
  mounted() {
    EventBus.on("location_field:data", this.onLocationFieldData);
    this.fetchData();
  },
  beforeDestroy() {
    EventBus.off("location_field:data");
  },
  watch: {
    currentPage() {
      this.fetchData();
    },
    fromAlarm: {
      handler(value) {
        if (value) {
          this.$emit("input", "from_alarm");
          this.model = null;
        } else {
          this.$emit("input", null);
        }
      },
      immediate: true,
    },
  },
  computed: {
    formattedOptions() {
      return this.options.map((camera) => ({
        value: camera.id,
        label: camera.name,
      }));
    },
    hasNextPage() {
      if (this.meta) {
        return this.currentPage + 1 <= this.meta.last_page;
      }
      return false;
    },
    hasPrevPage() {
      return this.currentPage - 1 > 0;
    },
  },
  methods: {
    fetchData() {
      return new Promise((resolve, reject) => {
        store
          .dispatch("cm_camera/getAll", {
            q: this.search,
            max: this.limit,
            "page[n]": this.currentPage,
            sortBy: "id",
            sortDesc: true,
          })
          .then((response) => {
            this.meta = response.meta;
            this.options = response.data;
            resolve();
          })
          .catch(() => {
            console.log("SelectPaginateAjax: Error obteniendo la información");
            reject();
          });
      });
    },
    onLocationFieldData(data) {
      if (!this.fromAlarm && !isNaN(data.radius)) {
        const lat = data.center.lat;
        const lng = data.center.lng;
        const radius = data.radius;
        const fields = "id,camera_id,camera.name";
        const url = `/v1/services/device/get-nearby-devices?lat=${lat}&lng=${lng}&radius=${radius}&fields=${fields}`;

        axios.get(url).then(({ data }) => {
          this.model = data.data.map((resp) => {
            return {
              value: resp.camera_id,
              label: resp.name,
            };
          });
          this.$emit("input", this.model);
        });
      }
    },
    onSearch: debounce(async function (query, loading) {
      if (query.length) {
        loading(true);
        this.search = query;
        await this.fetchData();
        loading(false);
      } else {
        this.currentPage = 1;
        this.search = "";
        this.fetchData(query);
      }
    }, 500),
    nextPage() {
      this.currentPage += 1;
    },
    prevPage() {
      this.currentPage--;
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  justify-content: space-around;
  margin: 0.25rem 0.25rem 0;
}
.pagination button {
  flex-grow: 1;
  &:first-child {
    margin-right: 5px;
  }
}
.pagination button:hover {
  cursor: pointer;
}
</style>
