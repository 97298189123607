<template>
  <div>
    <label class="d-block">{{ $t("commands.form.location.label") }}</label>
    <!-- <div class="row">
      <div class="col">
        <label for="lat_field">
          {{ $t("commands.form.location.fields.lat") }}
        </label>
        <input
          type="text"
          class="form-control"
          id="lat_field"
          :disabled="fromAlarm"
          v-model="latField"
          @input="onInput"
        />
      </div>
      <div class="col">
        <label for="lng_field">
          {{ $t("commands.form.location.fields.lng") }}
        </label>
        <input
          type="text"
          class="form-control"
          id="lng_field"
          :disabled="fromAlarm"
          v-model="lngField"
          @input="onInput"
        />
      </div>
    </div> -->
    <div class="map" ref="map" v-if="!fromAlarm">
      <div style="margin-top: 15px; border: 1px solid black; height: 300px">
        <units-map-slot
          mode="drawing"
          :data="mapData"
          @circle-updated="onCircleUpdated"
        />
      </div>
    </div>
    <div class="form-group form-check mt-1" v-if="showFromAlarm">
      <input
        type="checkbox"
        class="form-check-input"
        v-model="fromAlarm"
        value="from_alarm"
      />
      <label class="form-check-label">
        {{ $t("commands.form.location.fields.from_alarm") }}
      </label>
    </div>
  </div>
</template>

<script>
import UnitsMapSlot from "@/views/components/GridView/layouts/slots/UnitsMapSlot.vue";
import { EventBus } from "@/libs/event-bus";

export default {
  components: { UnitsMapSlot },
  props: {
    value: Array | Object,
    multiple: Boolean | Number,
    config: Object,
    context: Object,
  },
  data() {
    return {
      model: null,
      fromAlarm: false,
      latField: "",
      lngField: "",
      showFromAlarm: false,
      mapData: null,
    };
  },
  created() {
    this.showFromAlarm = this.context.flowchartConfig.alarm_compatible;

    this.model = this.value || "";
    if (this.showFromAlarm && this.model === "from_alarm") {
      this.fromAlarm = true;
    } else {
      const coords = this.model.split(",");
      this.latField = coords[0] || "";
      this.lngField = coords[1] || "";

      this.mapData = {
        drawingData: {
          lat: coords[0],
          lng: coords[1],
          radius: coords[2],
        },
      };
    }
  },
  async mounted() {},
  watch: {
    fromAlarm: {
      handler(value) {
        if (value) {
          this.latField = "";
          this.lngField = "";
          this.$emit("input", "from_alarm");
        } else {
          this.$emit("input", null);
        }
      },
      immediate: true,
    },
    flowchartConfig: {
      deep: true,
      handler(config) {
        this.showFromAlarm = config.alarm_compatible;
        this.fromAlarm = false;
        this.model = null;
        this.$emit("input", null);
      },
    },
  },
  methods: {
    onInput() {
      if (this.latField !== "" && this.lngField !== "") {
        this.$emit("input", `${this.latField},${this.lngField}`);
      } else {
        this.$emit("input", null);
      }
    },
    onCircleUpdated(event) {
      const action = event.action;
      const data = event.data;

      if (action === "updated") {
        this.latField = data.center.lat;
        this.lngField = data.center.lng;
        this.$emit("input", `${data.center.lat},${data.center.lng},${data.radius}`);
        data.radius = parseFloat(data.radius).toFixed(2);

        EventBus.emit("location_field:data", data);
      } else {
        this.latField = "";
        this.lngField = "";
        this.$emit("input", null);
        EventBus.emit("location_field:data", null);
      }
    },
  },
};
</script>

<style></style>
