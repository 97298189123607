<template>
  <div>
    <validation-provider
      :name="$t(config.name)"
      :rules="showFromAlarm ? 'required_if:units_from_alarm,false' : config.rules"
      v-slot="{ errors }"
    >
      <label class="d-block">
        {{ $t(config.label) }}
      </label>
      <v-select
        v-if="!unitsLoadingStatus"
        v-model="model"
        :options="unitsOptions"
        :clearable="false"
        :reduce="(val) => val.value"
        input-id="id_unit"
        :multiple="multiple"
        :disabled="fromAlarm"
        @input="$emit('input', model)"
      />
      <p v-else>Loading units...</p>
      <p v-if="config.hint" class="mb-0 small">{{ $t(config.hint) }}</p>
      <p v-if="errors" class="small text-danger">{{ errors[0] }}</p>
    </validation-provider>
    <div class="form-group form-check mt-1" v-if="showFromAlarm">
      <validation-provider vid="units_from_alarm">
        <input
          type="checkbox"
          class="form-check-input"
          name="units_from_alarm"
          v-model="fromAlarm"
          value="from_alarm"
        />
      </validation-provider>
      <label class="form-check-label">
        {{ $t("commands.form.location.fields.from_alarm") }}
      </label>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { ValidationProvider } from "vee-validate";
import store from "@/store";

export default {
  components: {
    vSelect,
    ValidationProvider,
  },
  props: {
    value: Array | Object,
    multiple: Boolean | Number,
    config: Object,
    context: Object,
  },
  data() {
    return {
      model: null,
      options: [],
      fromAlarm: false,
      showFromAlarm: false,
    };
  },
  created() {
    this.showFromAlarm = this.context.flowchartConfig.alarm_compatible;
    this.model = this.value || null;
    this.fromAlarm = this.showFromAlarm && this.value === "from_alarm";
  },
  mounted() {
    if (!this.units.length) {
      store.dispatch("unit/getUnitsByTenant");
    }
  },
  watch: {
    fromAlarm: {
      handler(value) {
        if (value) {
          this.$emit("input", "from_alarm");
          this.model = null;
        } else {
          this.$emit("input", null);
        }
      },
      immediate: true,
    },
  },
  computed: {
    units() {
      return store.getters["unit/getSelectedItems"];
    },
    unitsOptions() {
      if (this.options.length) {
        return this.options;
      }

      this.options = [...this.units];

      return this.options;
    },
    unitsLoadingStatus() {
      return store.getters["unit/getUnitsLoadingStatus"];
    },
  },
};
</script>

<style></style>
