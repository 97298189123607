<template>
  <div class="d-flex flex-column justify-content-around">
    <validation-observer ref="observer" tag="form">
      <div
        class="field mb-1 w-100"
        v-for="(field, index) in requirements"
        :key="index"
      >
        <units-select
          v-if="field.type === 'units'"
          :multiple="field.multiple == 1"
          v-model="model[field.type]"
          :context="context"
          :config="fields[field.type]"
        ></units-select>
        <cameras-select
          v-else-if="field.type === 'cameras'"
          :multiple="field.multiple == 1"
          v-model="model[field.type]"
          :context="context"
          :config="fields[field.type]"
        ></cameras-select>
        <user-views-select
          v-else-if="field.type === 'user_views'"
          :multiple="field.multiple == 1"
          v-model="model[field.type]"
          :context="context"
          :config="fields[field.type]"
        ></user-views-select>
        <users-select
          v-else-if="field.type === 'users'"
          :multiple="field.multiple == 1"
          v-model="model[field.type]"
          :context="context"
          :config="fields[field.type]"
        ></users-select>
        <interphones-select
          v-else-if="['interphone_from', 'interphone_to'].includes(field.type)"
          :type="field.type"
          :multiple="field.multiple == 1"
          v-model="model[field.type]"
          :context="context"
          :config="fields[field.type]"
        ></interphones-select>
        <do-select
          v-else-if="field.type == 'do'"
          :type="field.type"
          :multiple="field.multiple == 1"
          v-model="model[field.type]"
          :context="context"
          :config="fields[field.type]"
        ></do-select>
        <message-template-select
          v-else-if="['message_template', 'message'].includes(field.type)"
          :type="field.type"
          :multiple="false"
          v-model="model[field.type]"
          :context="context"
          :config="fields[field.type]"
        ></message-template-select>
        <density-devices-select
          v-else-if="field.type === 'density_device'"
          :multiple="field.multiple == 1"
          :type="field.type"
          v-model="model[field.type]"
          :context="context"
          :config="fields[field.type]"
        ></density-devices-select>
        <type-notification-select
          v-else-if="field.type === 'type'"
          :multiple="field.multiple == 1"
          :type="field.type"
          v-model="model[field.type]"
          :context="context"
          :config="fields[field.type]"
        ></type-notification-select>
        <type-webhook-verb-select
          v-else-if="field.type === 'verb' && model.type == 'webhook'"
          :multiple="field.multiple == 1"
          :type="field.type"
          v-model="model[field.type]"
          :context="context"
          :config="fields[field.type]"
        ></type-webhook-verb-select>
        <action-plan-select
          v-else-if="field.type === 'action_plan'"
          :multiple="field.multiple == 1"
          :type="field.type"
          v-model="model[field.type]"
          :context="context"
          :config="fields[field.type]"
        ></action-plan-select>
        <layout-select
          v-else-if="field.type === 'layout'"
          :multiple="field.multiple == 1"
          :type="field.type"
          v-model="model[field.type]"
          :context="context"
          :config="fields[field.type]"
        ></layout-select>
        <location-field
          v-else-if="field.type === 'location'"
          :multiple="field.multiple == 1"
          :type="field.type"
          v-model="model[field.type]"
          :context="context"
          :config="fields[field.type]"
        />
        <template v-else-if="fields[field.type].is_generic">
          <generic-field
            v-if="field.type === 'authorization' && model.type == 'webhook'"
            :multiple="field.multiple == 1"
            :type="field.type"
            v-model="model[field.type]"
            :config="fields[field.type]"
            :context="context"
          ></generic-field>
          <generic-field
            v-if="!['authorization'].includes(field.type)"
            :multiple="field.multiple == 1"
            :type="field.type"
            v-model="model[field.type]"
            :config="fields[field.type]"
            :context="context"
          ></generic-field>
        </template>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import UnitsSelect from "./UnitsSelect";
import CamerasSelect from "./CamerasSelect";
import UserViewsSelect from "./UserViewsSelect";
import GenericField from "./GenericField";
import UsersSelect from "./UsersSelect.vue";
import InterphonesSelect from "./InterphonesSelect.vue";
import DoSelect from "./DoSelect.vue";
import MessageTemplateSelect from "./MessageTemplateSelect.vue";
import PhoneField from "./PhoneField.vue";
import DensityDevicesSelect from "./DensityDevicesSelect.vue";
import TypeNotificationSelect from "./TypeNotificationSelect.vue";
import TypeWebhookVerbSelect from "./TypeWebhookVerbSelect.vue";
import ActionPlanSelect from "./ActionPlanSelect.vue";
import LayoutSelect from "./LayoutSelect.vue";
import LocationField from "./LocationField.vue";
import GroupsSelect from "./GroupsSelect.vue";
import TenantsSelect from "./TenantsSelect.vue";
import CentersSelect from "./CentersSelect.vue";
import SectorsSelect from "./SectorsSelect.vue";
import FilterField from "./FilterField.vue";
import fields from "@/libs/CommandFields/fields";

export default {
  components: {
    ValidationObserver,
    UnitsSelect,
    CamerasSelect,
    UserViewsSelect,
    GenericField,
    UsersSelect,
    InterphonesSelect,
    DoSelect,
    MessageTemplateSelect,
    PhoneField,
    DensityDevicesSelect,
    TypeNotificationSelect,
    TypeWebhookVerbSelect,
    ActionPlanSelect,
    LayoutSelect,
    LocationField,
    GroupsSelect,
    TenantsSelect,
    CentersSelect,
    SectorsSelect,
    FilterField,
  },
  props: {
    value: Array | Object,
    requirements: Array,
    flowchartConfig: {
      type: Object,
      default: () => ({ alarm_compatible: false }),
    },
  },
  data() {
    return {
      model: {},
      fields,
    };
  },
  created() {
    this.model = this.value || {};
  },
  watch: {
    model: {
      deep: true,
      handler(val) {
        this.$emit("input", val);
      },
    },
  },
  computed: {
    context() {
      return {
        flowchartConfig: this.flowchartConfig,
        model: this.model,
      };
    },
  },
};
</script>

<style></style>
