export default {
  data() {
    return {
      nodeId: null,
      nodeData: null,
    }
  },
  async mounted() {
    await this.$nextTick();
    this.getNodeId();
    this.getNodeData();
  },
  methods: {
    getNodeId() {
      if (!this.nodeId) {
        this.nodeId = this.$refs.el.parentElement.parentElement.id.slice(5);
      }
      return this.nodeId;
    },
    getNodeData() {
      if (!this.nodeData) {
        this.nodeData = this.$df.getNodeFromId(this.nodeId);
      }
      return this.nodeData;
    },
    updateNodeData() {
      this.$df.updateNodeDataFromId(this.nodeId, this.nodeData.data);
    },
  },
}
