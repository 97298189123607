<template>
  <div>
    <label class="d-block text-capitalize">
      {{ type.replace("_", " ") }}
    </label>
    <validation-provider
      :name="$t(config.name)"
      :rules="config.rules"
      v-slot="{ errors }"
    >
      <v-select
        v-model="model"
        :options="formattedOptions"
        :filterable="false"
        @search="onSearch"
        :multiple="multiple"
        :clereable="multiple"
        ref="selectable"
        @input="$emit('input', model)"
      >
        <template #list-footer class="pagination">
          <li class="pagination">
            <b-button
              variant="outline-primary"
              size="sm"
              :disabled="!hasPrevPage"
              @click="prevPage"
            >
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </b-button>
            <b-button
              variant="outline-primary"
              size="sm"
              :disabled="!hasNextPage"
              @click="nextPage"
            >
              <feather-icon icon="ChevronRightIcon" size="18" />
            </b-button>
          </li>
        </template>
      </v-select>
      <p v-if="config.hint" class="mb-0 small">{{ $t(config.hint) }}</p>
      <p v-if="errors" class="small text-danger">{{ errors[0] }}</p>
    </validation-provider>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { debounce } from "lodash";
import { BButton } from "bootstrap-vue";
import { ValidationProvider } from "vee-validate";
import store from "@/store";

export default {
  components: {
    vSelect,
    BButton,
    ValidationProvider,
  },
  props: {
    value: Array | Object,
    type: String,
    multiple: { type: Boolean, required: false, default: false },
    config: Object,
    context: Object,
  },
  data() {
    return {
      model: null,
      search: "",
      offset: 0,
      limit: 8,
      currentPage: 1,
      meta: null,
      options: [],
    };
  },
  created() {
    this.model = this.value;
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    currentPage() {
      this.fetchData();
    },
  },
  computed: {
    formattedOptions() {
      return this.options.map((item) => ({
        value: item.id,
        label: item.device_id,
      }));
    },
    hasNextPage() {
      if (this.meta) {
        return this.currentPage + 1 <= this.meta.last_page;
      }
      return false;
    },
    hasPrevPage() {
      return this.currentPage - 1 > 0;
    },
  },
  methods: {
    fetchData() {
      return new Promise((resolve, reject) => {
        store
          .dispatch("device_interphone/getAll", {
            q: this.search,
            max: this.limit,
            "page[n]": this.currentPage,
            sortBy: "id",
            sortDesc: true,
          })
          .then((response) => {
            this.meta = response.meta;
            this.options = response.data;
            resolve();
          })
          .catch(() => {
            console.log("SelectPaginateAjax: Error obteniendo la información");
            reject();
          });
      });
    },
    onSearch: debounce(async function (query, loading) {
      if (query.length) {
        loading(true);
        this.search = query;
        await this.fetchData();
        loading(false);
      } else {
        this.currentPage = 1;
        this.search = "";
        this.fetchData(query);
      }
    }, 500),
    nextPage() {
      this.currentPage += 1;
    },
    prevPage() {
      this.currentPage--;
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  justify-content: space-around;
  margin: 0.25rem 0.25rem 0;
}
.pagination button {
  flex-grow: 1;
  &:first-child {
    margin-right: 5px;
  }
}
.pagination button:hover {
  cursor: pointer;
}
</style>
