import { SOURCES } from "@/config/layoutConfig";
import store from "@/store";


export default {
  methods: {
    setSlotData(type, name, data, source = null) {
      store.dispatch("grid/setSlotData", {
        type,
        name,
        data: {
          ...this.removeReactivity(data),
          in_app_source: source,
        },
        source: source ? source : SOURCES.ALARM
      });
    },
    resetSlotSelection() {
      store.dispatch("grid/resetSlotSelection");
    },
    buildSlot(name, position, data, type) {
      return {
        name,
        pos: position,
        data,
        type
      }
    },
    placeSlots(slots) {
      store.commit("grid/forceLayoutSlots", slots);
    },
    addItemToLayout(slot) {
      store.commit("grid/unsetLayoutSlot", slot.pos);
      store.dispatch('grid/addItemToLayout', slot);
    },
    removeReactivity(data) {
      return JSON.parse(JSON.stringify(data));
    },
  },
}
