<template>
  <div>
    <label class="d-block">
      {{ $t(config.label) }}
      <span id="placeholders-help">
        <feather-icon icon="HelpCircleIcon" size="16" />
      </span>
      <span class="ml-50"
        >{{ chartsCount }}
        {{ $t("commands.form.message_template.characters_remaining") }}</span
      >
    </label>
    <b-tooltip target="placeholders-help" placement="right">
      {{ $t("commands.form.message_template.help.header") }}
      <table class="table table-sm text-white my-1">
        <tr v-for="(ph, index) in placeholders" :key="index">
          <th>{{ ph.placeholder }}</th>
          <td>{{ ph.description }}</td>
        </tr>
      </table>
      {{ $t("commands.form.message_template.help.footer.title") }}: <br />
      {{ $t("commands.form.message_template.help.footer.body") }}
    </b-tooltip>
    <!-- <textarea
      v-model="model"
      cols="10"
      rows="2"
      class="form-control"
      @input="$emit('input', model)"
    ></textarea> -->
    <validation-provider
      :name="$t(config.name)"
      :rules="config.rules"
      v-slot="{ errors }"
    >
      <input
        type="text"
        v-model="model"
        class="form-control"
        @input="$emit('input', model)"
        :maxlength="maxChars"
      />
      <p v-if="config.hint" class="mb-0 small">{{ $t(config.hint) }}</p>
      <p v-if="errors" class="small text-danger">{{ errors[0] }}</p>
    </validation-provider>
    <p class="small">
      {{ $t("commands.form.message_template.available_placeholders") }}:
      <span class="text-lowercase">
        {{ placeholders.map((it) => it.placeholder).join(", ") }}
      </span>
    </p>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import axios from "@axios";

export default {
  components: {
    ValidationProvider,
  },
  props: {
    value: Array | Object,
    multiple: Boolean | Number,
    config: Object,
    context: Object,
  },
  data() {
    const defaultChars = 35;

    return {
      data: [],
      model: null,
      maxChars: 1024 - defaultChars,
    };
  },
  created() {
    this.model = this.value;
  },
  async mounted() {
    const { data } = await axios.get("v1/command/placeholders");
    this.data = data.data;
  },
  computed: {
    chartsCount() {
      const chars = this.maxChars - (this.model ? this.model.length : 0);
      return chars <= 0 ? 0 : chars;
    },
    placeholders() {
      return this.data.map((ph) => ({
        description: ph.description,
        placeholder: `@${ph.placeholder}`,
      }));
    },
  },
};
</script>

<style></style>
