<template>
  <b-modal
    id="cmd_config_modal"
    ref="cmd_config_modal"
    title="Command Config"
    @show="onShow"
    @hidden="resetDataModal"
    hide-header
    centered
    no-close-on-esc
    no-close-on-backdrop
    scrollable
  >
    <p class="h3 mb-0">{{ $t('commands.config_modal.title') }}</p>
    <hr class="mb-2" />
    <node-data
      ref="nodeData"
      :requirements="currentNodeRequirements"
      :flowchart-config="flowchartConfig"
      v-model="model"
    ></node-data>
    <template v-if="formHasErrors">
      <hr />
      <p class="text-danger">
        {{ $t('commands.config_modal.messages.invalid_form') }}
      </p>
    </template>

    <template #modal-footer="{ close }">
      <div class="">
        <b-button variant="danger" size="sm" class="float-left mr-1" @click="close()">
          {{ $t("commands.modal.btns.cancel") }}
        </b-button>
        <b-button
          variant="primary"
          size="sm"
          class="float-right"
          @click="onContinueClick(close)"
        >
          {{ $t("commands.modal.btns.continue") }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { BSpinner } from "bootstrap-vue";
import NodeData from "@/components/flowchart/nodes/commands/NodeData";
import fields from "@/libs/CommandFields/fields";

export default {
  components: {
    BSpinner,
    NodeData,
  },
  props: {
    currentNodeRequirements: {
      type: Array,
      default: [],
    },
    flowchartConfig: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      model: {},
      formHasErrors: false,
      fields,
    };
  },
  watch: {
    model: {
      deep: true,
      handler(val) {
        this.setCameraAssignmentRules(val);
      },
    },
  },
  computed: {},
  methods: {
    onShow() {
      this.model = JSON.parse(JSON.stringify(this.value));
    },
    async onContinueClick(close) {
      const nodeDataRefs = this.$refs.nodeData;
      const resultValidation = await nodeDataRefs.$refs.observer.validate();
      this.formHasErrors = !resultValidation;

      if (!this.formHasErrors) {
        this.formHasErrors = false;
        this.$emit("input", this.model);
        close();
      }
    },
    resetDataModal() {
      this.model = {};
      this.formHasErrors = false;
    },
    setCameraAssignmentRules(val) {
      if (val) {

        if (this.model.users) {
          if (this.model.users.length > 0) {
            this.fields.groups.rules = null;
          } else {
            this.fields.groups.rules = "required";
            this.fields.users.rules = "required";
          }
        }

        if (this.model.groups) {
          if (this.model.groups.length > 0) {
            this.fields.users.rules = null;
          } 
        }

        if (this.model.tenants) {
          if (this.model.tenants.length > 0) {
            this.fields.centers.rules = null;
            this.fields.sectors.rules = null;
          } else {
            this.fields.centers.rules = "required";
            this.fields.sectors.rules = "required";
          }
        }

        if (this.model.centers) {
          if (this.model.centers.length > 0) {
            this.fields.tenants.rules = null;
            this.fields.sectors.rules = null;
          } else {
            this.fields.tenants.rules = "required";
          }
        }

        if (this.model.sectors) {
          if (this.model.sectors.length > 0) {
            this.fields.tenants.rules = null;
            this.fields.centers.rules = null;
          }
        }
      }
    },
  },
};
</script>

<style></style>
