export default {

  units: {
    type: 'autocomplete',
    is_generic: false,
    name: "commands.data_types.units",
    field_name: "units",
    label: 'commands.form.units_select.label',
    rules: 'required',
    validator: null,
    hint: '',
  },

  cameras: {
    type: 'autocomplete',
    is_generic: false,
    name: "commands.data_types.cameras",
    field_name: "cameras",
    label: 'commands.form.cameras_select.label',
    rules: 'required',
    validator: null,
    hint: '',
  },

  user_views: {
    type: 'autocomplete',
    is_generic: false,
    name: "commands.data_types.user_views",
    field_name: "user_views",
    label: 'commands.form.user_views_select.label',
    rules: 'required',
    validator: null,
    hint: '',
  },

  users: {
    type: 'autocomplete',
    is_generic: false,
    name: "commands.data_types.users",
    field_name: "users",
    label: 'commands.form.users_select.label',
    rules: 'required',
    validator: null,
    hint: '',
  },

  interphone_from: {
    type: 'autocomplete',
    is_generic: false,
    name: "commands.data_types.interphone_from",
    field_name: "interphone_from",
    label: 'interphone_from',
    rules: 'required',
    validator: null,
    hint: '',
  },

  interphone_to: {
    type: 'autocomplete',
    is_generic: false,
    name: "commands.data_types.interphone_to",
    field_name: "interphone_to",
    label: 'interphone_to',
    rules: 'required',
    validator: null,
    hint: '',
  },

  do: {
    type: 'autocomplete',
    is_generic: false,
    name: "commands.data_types.do",
    field_name: "do",
    label: 'commands.form.user_views_select.label',
    rules: 'required',
    validator: null,
    hint: '',
  },

  message_template: {
    type: 'text',
    is_generic: false,
    name: "commands.data_types.message_template",
    field_name: "message_template",
    label: 'commands.form.message_template.label',
    rules: 'required',
    validator: null,
    hint: '',
  },

  // Type notification
  type: {
    is_generic: false,
    type: 'autocomplete',
    is_generic: false,
    name: "commands.data_types.type",
    field_name: "type",
    label: 'commands.form.type_notificaction.label',
    rules: 'required',
    validator: null,
    hint: '',
  },

  verb: {
    name: "commands.data_types.verb",
    field_name: "verb",
    type: 'autocomplete',
    is_generic: false,
    label: 'commands.form.type_verb_webhook.label',
    rules: 'required',
    validator: null,
    hint: '',
  },

  webhook: {
    type: 'autocomplete',
    is_generic: false,
    name: "commands.data_types.webhook",
    field_name: "webhook",
    label: 'commands.form.type_verb_webhook.label',
    rules: 'required',
    validator: null,
    hint: '',
  },

  action_plan: {
    type: 'autocomplete',
    is_generic: false,
    name: "commands.data_types.action_plan",
    field_name: "action_plan",
    label: 'commands.form.action_plan.label',
    rules: 'required',
    validator: null,
    hint: '',
  },

  // Layout selection
  layout: {
    type: 'autocomplete',
    is_generic: false,
    name: "commands.data_types.layout",
    field_name: "layout",
    label: 'Layouts',
    rules: 'required',
    validator: null,
    hint: '',
  },

  location: {
    type: 'custom',
    is_generic: false,
    name: "commands.data_types.location",
    field_name: "location",
    label: 'commands.form.location.label',
    rules: 'required',
    validator: null,
    hint: '',
  },

  density_device: {
    type: 'autocomplete',
    is_generic: false,
    name: "commands.data_types.density_device",
    field_name: "density_device",
    label: 'commands.form.density_device.label',
    rules: 'required',
    validator: null,
    hint: '',
  },

  email: {
    type: "select",
    is_generic: true,
    name: "commands.data_types.email",
    field_name: "email",
    label: 'commands.form.email.label',
    rules: 'required',
    validator: null,
    hint: '',
  },

  phone: {
    type: "select",
    is_generic: true,
    name: "commands.data_types.phone",
    field_name: "phone",
    label: 'commands.form.phone.label',
    rules: 'required',
    validator: null,
    hint: '',
  },

  receiver: {
    type: "text",
    is_generic: true,
    name: "commands.data_types.receiver",
    field_name: "receiver",
    label: 'commands.form.receiver.label',
    rules: 'required',
    validator: null,
    hint: '',
  },

  authorization: {
    type: "text",
    is_generic: true,
    name: "commands.data_types.authorization",
    field_name: "authorization",
    label: 'commands.form.authorization.label',
    rules: 'required',
    validator: null,
    hint: '',
  },

  url1: {
    type: "text",
    is_generic: true,
    name: "commands.data_types.url1",
    field_name: "url1",
    label: 'commands.form.url1.label',
    rules: 'required',
    validator: null,
    hint: '',
  },

  url2: {
    type: "text",
    is_generic: true,
    name: "commands.data_types.url2",
    field_name: "url2",
    label: 'commands.form.url2.label',
    rules: 'required',
    validator: null,
    hint: '',
  },

  delay: {
    type: "number",
    is_generic: true,
    name: "commands.data_types.delay",
    field_name: "delay",
    label: 'commands.form.delay.label',
    rules: 'required|min_value:8',
    validator: null,
    properties: [
      { name: "min", value: 8 },
    ],
    hint: "commands.units.seconds",
  },

  radius: {
    type: "number",
    is_generic: true,
    name: "commands.data_types.radius",
    field_name: "radius",
    label: 'commands.form.radius.label',
    rules: 'required|min_value:10',
    validator: null,
    properties: [
      { name: "min", value: 10 }
    ],
    hint: "commands.units.meters",
  },

  loop: {
    type: "boolean",
    is_generic: true,
    name: "commands.data_types.loop",
    field_name: "loop",
    options: {
      default: false,
    },
    label: 'commands.form.loop.label',
    rules: null,
    validator: null,
  },

  groups: {
    type: 'autocomplete',
    is_generic: false,
    name: "commands.data_types.groups",
    field_name: "groups",
    label: 'commands.form.groups_select.label',
    rules: 'required',
    validator: null,
    hint: '',
  },

  tenants: {
    type: 'autocomplete',
    is_generic: false,
    name: "commands.data_types.tenants",
    field_name: "tenants",
    label: 'commands.form.tenants_select.label',
    rules: 'required',
    validator: null,
    hint: '',
  },

  centers: {
    type: 'autocomplete',
    is_generic: false,
    name: "commands.data_types.centers",
    field_name: "centers",
    label: 'commands.form.centers_select.label',
    rules: 'required',
    validator: null,
    hint: '',
  },

  sectors: {
    type: 'autocomplete',
    is_generic: false,
    name: "commands.data_types.sectors",
    field_name: "sectors",
    label: 'commands.form.sectors_select.label',
    rules: 'required',
    validator: null,
    hint: '',
  },

  filter: {
    type: 'autocomplete',
    is_generic: false,
    name: "commands.data_types.filter",
    field_name: "filter",
    label: 'commands.form.filter_field.label',
    rules: null,
    validator: null,
    hint: '',
  },
}
